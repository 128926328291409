@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --expo-out: linear(
      0 0%, 0.1684 2.66%, 0.3165 5.49%,
      0.446 8.52%, 0.5581 11.78%,
      0.6535 15.29%, 0.7341 19.11%,
      0.8011 23.3%, 0.8557 27.93%,
      0.8962 32.68%, 0.9283 38.01%,
      0.9529 44.08%, 0.9711 51.14%,
      0.9833 59.06%, 0.9915 68.74%, 1 100%
    );
  }

  ::view-transition-group(root) {
    animation-duration: 0.7s;
    animation-timing-function: var(--expo-out);
  }
  ::view-transition-new(root) {
    animation-name: reveal-light;
  }
  ::view-transition-old(root),
  .dark::view-transition-old(root) {
    animation: none;
    z-index: -1;
  }
  .dark::view-transition-new(root) {
    animation-name: reveal-dark;
  }

  @keyframes reveal-light {
    from {
      clip-path: polygon(50% -71%, 150% 71%, 150% 71%, 50% -71%);
    }
    to {
      clip-path: polygon(50% -71%, 150% 71%, 50% 171%, -50% 71%);
    }
  }
  @keyframes reveal-dark {
    from {
      clip-path: polygon(-71% 50%, 50% 171%, 50% 171%, -71% 50%);
    }
    to {
      clip-path: polygon(-71% 50%, 50% 171%, 150% 71%, 71% -50%);
    }
  }

  * {
    font-variant-numeric: tabular-nums;
    scrollbar-width: thin;
  }

  @font-face {
    font-family: "Satoshi";
    src: url("../fonts/Satoshi-Light.woff2") format("woff2"),
      url("../fonts/Satoshi-Light.woff") format("woff"),
      url("../fonts/Satoshi-Light.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "Satoshi";
    src: url("../fonts/Satoshi-LightItalic.woff2") format("woff2"),
      url("../fonts/Satoshi-LightItalic.woff") format("woff"),
      url("../fonts/Satoshi-LightItalic.ttf") format("truetype");
    font-weight: 300;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: "Satoshi";
    src: url("../fonts/Satoshi-Regular.woff2") format("woff2"),
      url("../fonts/Satoshi-Regular.woff") format("woff"),
      url("../fonts/Satoshi-Regular.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "Satoshi";
    src: url("../fonts/Satoshi-Italic.woff2") format("woff2"),
      url("../fonts/Satoshi-Italic.woff") format("woff"),
      url("../fonts/Satoshi-Italic.ttf") format("truetype");
    font-weight: 400;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: "Satoshi";
    src: url("../fonts/Satoshi-Medium.woff2") format("woff2"),
      url("../fonts/Satoshi-Medium.woff") format("woff"),
      url("../fonts/Satoshi-Medium.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "Satoshi";
    src: url("../fonts/Satoshi-MediumItalic.woff2") format("woff2"),
      url("../fonts/Satoshi-MediumItalic.woff") format("woff"),
      url("../fonts/Satoshi-MediumItalic.ttf") format("truetype");
    font-weight: 500;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: "Satoshi";
    src: url("../fonts/Satoshi-Bold.woff2") format("woff2"),
      url("../fonts/Satoshi-Bold.woff") format("woff"),
      url("../fonts/Satoshi-Bold.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "Satoshi";
    src: url("../fonts/Satoshi-BoldItalic.woff2") format("woff2"),
      url("../fonts/Satoshi-BoldItalic.woff") format("woff"),
      url("../fonts/Satoshi-BoldItalic.ttf") format("truetype");
    font-weight: 700;
    font-display: swap;
    font-style: italic;
  }

  @font-face {
    font-family: "Satoshi";
    src: url("../fonts/Satoshi-Black.woff2") format("woff2"),
      url("../fonts/Satoshi-Black.woff") format("woff"),
      url("../fonts/Satoshi-Black.ttf") format("truetype");
    font-weight: 900;
    font-display: swap;
    font-style: normal;
  }

  @font-face {
    font-family: "Satoshi";
    src: url("../fonts/Satoshi-BlackItalic.woff2") format("woff2"),
      url("../fonts/Satoshi-BlackItalic.woff") format("woff"),
      url("../fonts/Satoshi-BlackItalic.ttf") format("truetype");
    font-weight: 900;
    font-display: swap;
    font-style: italic;
  }

  [data-collapse="true"] {
    display: none;
  }

  dialog::backdrop {
    pointer-events: none;
  }

  form[inert] {
    @apply opacity-50;
  }

  input[type="checkbox"] {
    @apply w-[1.125rem] h-[1.125rem] appearance-none border border-slate-950/10 dark:border-white/10 rounded dark:checked:bg-white checked:bg-slate-950;
  }

  input:checked[type="checkbox"] {
    background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%23ffffff%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e);
  }

  select {
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 6"%3E%3Cpath stroke="%236B7280" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 4 4 4-4"/%3E%3C/svg%3E');
    @apply appearance-none bg-[right_.75rem_center] bg-no-repeat bg-[length:.75em_.75em];
  }

  html.dark {
    color-scheme: dark;
    input:checked[type="checkbox"] {
      background-image: url(data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 20 20%27%3e%3cpath fill=%27none%27 stroke=%27%230c4a6e%27 stroke-linecap=%27round%27 stroke-linejoin=%27round%27 stroke-width=%273%27 d=%27m6 10 3 3 6-6%27/%3e%3c/svg%3e);
    }
  }

  .invalid-feedback {
    @apply mt-1 text-red-500 text-xs;
  }
}

#logoGaladaGlobal {
  .tentacle {
    animation: slide 1s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite both;
  }
  .tentacle-01 {
    animation-delay: 0.3s;
  }
  .tentacle-02 {
    animation-delay: 0.2s;
  }
  .tentacle-03 {
    animation-delay: 0.1s;
  }
  .tentacle-04 {
    animation-delay: 0.5s;
  }
  .tentacle-05 {
    animation-delay: 0.4s;
  }
  .tentacle-06 {
    animation-delay: 0.7s;
  }
  .tentacle-07 {
    animation-delay: 0.6s;
  }
  .eye {
    animation: close 6s cubic-bezier(0.250, 0.460, 0.450, 0.940) infinite alternate;
  }
}

@keyframes slide {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(1.5%);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes close {
  0% {
    transform: scaleY(100%);
  }
  48% {
    transform: scaleY(100%);
  }
  49% {
    transform: scaleY(30%);
  }
  51% {
    transform: scaleY(30%);
  }
  52% {
    transform: scaleY(100%);
  }
  100% {
    transform: scaleY(100%);
  }
}

/* TomSelect */

.ts-wrapper.multi,
.ts-wrapper.multi.has-item {
  .ts-control {
    @apply shadow-none bg-transparent block px-2 pb-2 !pt-4 w-full max-h-52 overflow-auto text-sm text-slate-950 dark:bg-slate-900 rounded-lg border border-slate-950/10 dark:text-white dark:border-white/10 dark:focus:border-white focus:outline-none focus:ring-0 focus:border-slate-950;
    input[type="text"] {
      @apply text-slate-950 dark:text-white !important;
    }
    .clear-button {
      @apply bg-slate-950/10 dark:bg-slate-700 px-1 border-0 text-slate-950 m-0 top-1 right-1 rounded-full flex items-center justify-center h-5 w-5 translate-y-0 !important;
      svg {
        @apply fill-slate-950 dark:fill-white !important;
      }
    }
    .item {
      @apply bg-none bg-lime-500 border-0 text-slate-950 font-bold text-xs [text-shadow:none] !important;
      a {
        @apply border-0 !important;
      }
    }
  }
  .ts-dropdown {
    @apply z-20 dark:bg-slate-900 dark:border-white/10 dark:text-white;
    .ts-dropdown-content {
      .option {
        &.active {
          @apply dark:bg-white/10 dark:text-white;
        }
      }
    }
    &.dropup {
      @apply m-0 bottom-[calc(100%+0.25rem)] rounded-t-[3px] rounded-b-none top-auto !important;
    }
    &.dropup .ts-dropdown-content {
      @apply rounded-t-[3px] border-t border-white/10 !important;
    }
    .optgroup {
      @apply border-none !important;
    }
  }
}

/* ApexCharts */

.apexcharts-tooltip {
  @apply flex flex-col gap-2 p-3 dark:border-slate-600 dark:bg-slate-700 !important;
}

.apexcharts-tooltip-title {
  @apply bg-transparent p-0 border-0 m-0 font-bold !important;
}

.apexcharts-tooltip-series-group,
.apexcharts-tooltip-y-group {
  @apply !p-0;
}

.apexcharts-text {
  @apply p-2 dark:fill-white !important;;
}

.apexcharts-title-text {
  @apply fill-black dark:fill-white !important;
}

.apexcharts-grid-borders {
  .apexcharts-gridline {
    @apply stroke-black/20 dark:stroke-white/20;
  }
  :not(.apexcharts-gridline) {
    @apply stroke-transparent dark:stroke-transparent !important;
  }
}

.apexcharts-gridlines-horizontal {
  .apexcharts-gridline {
    @apply stroke-black/20 dark:stroke-white/20;
  }
}

.apexcharts-xaxis-tick {
  @apply stroke-black/20 dark:stroke-white/20 !important;
}

.apexcharts-legend-text {
  @apply dark:text-white !important;
}

.apexcharts-xaxistooltip {
  @apply dark:border-slate-600 dark:bg-slate-700 dark:text-white dark:before:border-b-slate-600 dark:after:border-b-slate-700 !important;
}

.apexcharts-yaxis-title text {
  font-family: inherit !important;
}

.apexcharts-marker {
  @apply stroke-none;
}

.ag-theme-quartz,
.ag-theme-quartz-dark {
  --ag-font-family: "Satoshi" !important;
  --ag-grid-size: 12px !important;

  --ag-cell-horizontal-padding: calc(var(--ag-grid-size) * 1) !important;
}

.ag-theme-quartz {
  --ag-background-color: theme("colors.white") !important;
  --ag-header-background-color: theme("colors.white") !important;

  --ag-border-color: theme("colors.slate.200") !important;
  --ag-row-border-color: theme("colors.slate.200") !important;
  --ag-row-hover-color: theme("colors.slate.200") !important;
}

.ag-theme-quartz .ag-header-row {
  @apply font-bold !important;
}

.ag-theme-quartz-dark {
  --ag-background-color: theme("colors.slate.900") !important;
  --ag-header-background-color: theme("colors.slate.800") !important;

  --ag-border-color: theme("colors.slate.800") !important;
  --ag-row-border-color: theme("colors.slate.800") !important;
  --ag-row-hover-color: theme("colors.slate.800") !important;
}

.ag-header-cell-label {
  @apply gap-1 !important;
}

.ag-row-pinned {
  @apply font-bold dark:bg-slate-800 dark:text-white !important;
}

.ag-floating-bottom-viewport {
  @apply dark:bg-slate-800 dark:text-white !important;
}

.ag-paging-page-size,
.ag-paging-row-summary-panel {
  @apply hidden sm:block;
}
